<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <ChartDoughnutCard
          id="mergeDuplicateSetsTile"
          :is-loading="isLoadingDuplicatesToReviewStats"
          title="Duplicates on Entry to Review"
          title-tooltip="Contains the overall breakdown of possible duplicates on entry requiring admin review."
          :items="dupeSetsToReviewStatsByEntity"
          :description="`${dupeSetsToReviewCount} Possible duplicates`"
          :to="{ path: '/on-entry/review' }"
        />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <OnEntryAdminDashboardDataOffenders />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OnEntryAdminDashboardDataOffenders from './components/admin-dashboard/OnEntryAdminDashboardDataOffenders'
import ChartDoughnutCard from '@/components/common/ChartDoughnutCard'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    ChartDoughnutCard,
    OnEntryAdminDashboardDataOffenders,
  },
  data() {
    return {
      isLoadingDuplicatesToReviewStats: false,
    }
  },
  computed: {
    ...mapGetters('on-entry', [
      'dupeSetsToReviewStatsByEntity',
      'dupeSetsToReviewCount',
    ]),
  },
  mounted() {
    this.fetchDupesToReviewStats()
  },
  methods: {
    ...mapActions('on-entry', ['getDupeSetsToReviewStats']),
    async fetchDupesToReviewStats() {
      this.isLoadingDuplicatesToReviewStats = true
      await this.getDupeSetsToReviewStats()
      this.isLoadingDuplicatesToReviewStats = false
    },
  },
}
</script>
