<template>
  <ChartCard
    title="Data Offenders - Most Duplicates on Entry"
    title-tooltip="The list of users who created records that already existed in the system."
    :to="{ path: '/on-entry/review' }"
    to-label="SEE ALL"
  >
    <v-simple-table class="mt-4 min-w-full">
      <template #default>
        <thead>
          <tr>
            <th class="text-left ap-dark-gray--text">End User</th>
            <th class="text-left ap-dark-gray--text">Manager</th>
            <th class="text-right" />
            <th class="text-right ap-dark-gray--text">Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dataOffenders" :key="index">
            <td class="primary--text">{{ item.id }} {{ item.enduser }}</td>
            <td>{{ item.manager }}</td>
            <td width="20%" class="text-right">
              <v-progress-linear
                :value="item.count"
                color="red"
                height="11"
                rounded
              />
            </td>
            <td class="text-right" width="5%">
              {{ item.count }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </ChartCard>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ChartCard from '@/components/common/ChartCard'

export default {
  components: {
    ChartCard,
  },
  computed: {
    ...mapState('on-entry', ['dataOffenders']),
  },
  created() {
    this.getDataOffenders()
  },
  methods: {
    ...mapActions('on-entry', ['getDataOffenders']),
  },
}
</script>

<style scoped>
.theme--light.v-data-table .v-data-table__wrapper {
  border: none !important;
}

.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(.v-data-table__mobile-row):not(:last-child),
.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th:not(:last-child) {
  border-right: none;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none;
}
</style>
